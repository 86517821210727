// src/App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { ApolloProvider ,useApolloClient} from '@apollo/client';
import client from './ApolloClient';
import MerchandiseListPage from './pages/MerchandiseListPage';
import MerchandiseDetailPage from './pages/MerchandiseDetailPage';
import Home from './pages/Home';
import Login from './pages/Login'; 
import Register from './pages/Register';
import UserDashboard from './pages/UserDashboard';
import ArtistDashboard from './components/ArtistDashboard';
import MerchandiseDashboard from './components/MerchandiseDashboard';


const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const client = useApolloClient();

  useEffect(() => {
    const token = sessionStorage.getItem('token');
    if (token) {
      setIsLoggedIn(true);
    }
  }, []);

  const handleLoginSuccess = () => {
    setIsLoggedIn(true);
  };

  const handleLogout = () => {
    sessionStorage.removeItem('token');
    setIsLoggedIn(false);
   
    client.clearStore();
  };

  return (
    <ApolloProvider client={client}>
      <div style={{ padding: 20 }}>
        <Router>
          <nav>
            <Link to="/">Home</Link> | 
            <Link to="/merchandise"> Merchandise </Link> | 
            {isLoggedIn ? (
              <>
              <Link to="/udash"> Dashboard </Link> |  
              <button onClick={handleLogout}>Logout</button>
              
              </>
            ) : (
              <Link to="/login">Login</Link>
            )}
          </nav>
          <Routes>
            <Route path="/" element={<Home />} />  
            <Route path="/merchandise" element={<MerchandiseListPage />} />
            <Route path="/merchandise/:id" element={<MerchandiseDetailPage />} />
            <Route path="/login" element={<Login onLoginSuccess={handleLoginSuccess} />} />
            <Route path="/register" element={<Register/>} />
            <Route path="/udash" element={<UserDashboard/>} />
            <Route path="/artistdashboard/:artistPageId" element={<ArtistDashboard />} /> {/* Add this route */}
            <Route path="/merchandisedashboard/:merchPageId" element={<MerchandiseDashboard/>} /> {/* Add this route */}
          </Routes>
        </Router>
      </div>
    </ApolloProvider>
  );
};

export default App;

