import React from 'react';
import './RegisterButton.css'
import { useNavigate } from 'react-router-dom';

function RegisterButton(props) {
    const navigate = useNavigate()

    function clickHandler() {
        
        navigate('/register')
        
    }
  return (
    <button className="register-button" onClick={clickHandler}>
      Register
    </button>
  );
}

export default RegisterButton;