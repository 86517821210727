// src/components/MerchandiseItem.js
import React from 'react';


const MerchandiseItem = ({ item, onAddToCart }) => {
  const { title, description, price, mainImage, additionalImages } = item;

  return (
    <div 
      className="merchandise-item" 
      style={{
        border: '3px solid #ccc',
        padding: '16px',
        margin: '16px 0',
        borderRadius: '8px',
      }}
    >
      <h3>{title}</h3>
      {mainImage && (
        <img 
          src={mainImage} 
          alt={title} 
          style={{
            width: '40%', 
            maxHeight: '800px', 
            objectFit: 'contain',
            display: 'block',
            margin: '0 auto',
            backgroundColor: 'black',
          }} 
        />
      )}

      <p>{description}</p>
      <p><strong>Price:</strong> ${price}</p>
      <div>
        {additionalImages && additionalImages.length > 0 && (
          <>
            <h4>More images:</h4>
            <div style={{ display: 'flex', gap: '8px', flexWrap: 'wrap' }}>
              {additionalImages.map((img, index) => (
                <img key={index} src={img} alt={`${title} ${index + 1}`} style={{ width: '100px', height: '100px', objectFit: 'cover' }} />
              ))}
            </div>
          </>
        )}
      </div>
      <button onClick={() => onAddToCart(item)}>Add to Cart</button>
    </div>
  );
};

export default MerchandiseItem;
