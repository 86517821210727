// src/pages/Login.js
import React, { useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import { useNavigate } from 'react-router-dom';

const LOGIN = gql`
  mutation Mutation($input: LoginInput!) {
    Login(input: $input) {
      id
      mobile
      firstName
      lastName
      email
      token
    }
  }
`;

function Login({ onLoginSuccess }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [login, { loading, error }] = useMutation(LOGIN);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await login({
        variables: {
          input: { email, password }
        }
      });

      if (data && data.Login && data.Login.token) {
        // Save the token to sessionStorage
        sessionStorage.setItem('token', data.Login.token);
        console.log("storing token: ", data.Login.token);
        const myblahtoken = sessionStorage.getItem('token')
        console.log("Retrieving Token: ",myblahtoken)

        // Notify parent component that login was successful
        onLoginSuccess();

        // Redirect to the Home page
        navigate('/');
      }
    } catch (err) {
      console.error("Login failed", err);
    }
  };

  return (
    <div>
      <h2>Login</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          required
        />
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          required
        />
        <button type="submit" disabled={loading}>
          {loading ? 'Logging in...' : 'Login'}
        </button>
      </form>
      {error && <p>Error: {error.message}</p>}
    </div>
  );
}

export default Login;

