import React, { useState } from 'react';
import './SignUp.css';
import { useMutation } from '@apollo/client';
import { gql } from '@apollo/client';

const ADD_LEAD_MUTATION = gql`
  mutation AddLead($input: LeadInput!) {
    addLead(input: $input) {
      id
      accountType
      bio
      firstName
      lastName
      mobile
      email
    }
  }
`;

function SignUp() {
  const [userType, setUserType] = useState('fan');
  const [artistType, setArtistType] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [mobile, setMobile] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);

  const [addLead] = useMutation(ADD_LEAD_MUTATION);

  const handleSignUp = (e) => {
    e.preventDefault();

    const input = {
      firstName,
      lastName,
      mobile,
      email,
      password,
      accountType: userType,
      bio: userType === 'artist' ? artistType : ''
    };

    addLead({ variables: { input } }).then(response => {
      alert('Lead added successfully: ' + JSON.stringify(response.data.addLead));
      setError(null); // Reset error state on success
    }).catch(error => {
      setError(error.message); // Set error message in state
    });
  };

  return (
    <form onSubmit={handleSignUp}>
      <h2>Sign Up</h2>
      {error && <p className="error-message">Error: {error}</p>}
      <div className="user-type-buttons">
        <button
          type="button"
          className={`user-type-button ${userType === 'fan' ? 'selected' : ''}`}
          onClick={() => {
            setUserType('fan');
            setArtistType('');
          }}
        >
          Fan
        </button>
        <button
          type="button"
          className={`user-type-button ${userType === 'artist' ? 'selected' : ''}`}
          onClick={() => setUserType('artist')}
        >
          Artist
        </button>
        <button
          type="button"
          className={`user-type-button ${userType === 'venue' ? 'selected' : ''}`}
          onClick={() => {
            setUserType('venue');
            setArtistType('');
          }}
        >
          Venue
        </button>
      </div>
      {userType === 'artist' && (
        <div className="artist-type-buttons">
          <button
            type="button"
            className={`artist-type-button ${artistType === 'solo' ? 'selected' : ''}`}
            onClick={() => setArtistType('solo')}
          >
            Solo Artist
          </button>
          <button
            type="button"
            className={`artist-type-button ${artistType === 'duet' ? 'selected' : ''}`}
            onClick={() => setArtistType('duet')}
          >
            Duet
          </button>
          <button
            type="button"
            className={`artist-type-button ${artistType === 'band' ? 'selected' : ''}`}
            onClick={() => setArtistType('band')}
          >
            Band
          </button>
          <button
            type="button"
            className={`artist-type-button ${artistType === 'karaoke' ? 'selected' : ''}`}
            onClick={() => setArtistType('karaoke')}
          >
            Karaoke
          </button>
        </div>
      )}
      <br />
      <label>
        First Name:
        <input
          type="text"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          required
        />
      </label>
      <br />
      <label>
        Last Name:
        <input
          type="text"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          required
        />
      </label>
      <br />
      <label>
        Mobile:
        <input
          type="text"
          value={mobile}
          onChange={(e) => setMobile(e.target.value)}
          required
        />
      </label>
      <br />
      <label>
        Email:
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
      </label>
      <br />
      <label>
        Password:
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
      </label>
      <br />
      <button type="submit" className='submit-button'>Sign Up</button>
      <br/>
      <br/>
      <br/>
      <div id='aspace'></div>
      
    </form>
  );
}

export default SignUp;
