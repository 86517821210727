// customFetch.js
import axios from 'axios';

const customAxiosFetch = (url, options = {}) => {
  const { method, body, headers, onProgress } = options;

  return axios({
    url,
    method: method || 'POST', // Default to POST if method not provided
    data: body,
    headers,
    onUploadProgress: (progressEvent) => {
      if (onProgress) {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        onProgress(percentCompleted); // Report progress
      }
    },
  })
    .then(response => {
      return new Response(JSON.stringify(response.data), {
        status: response.status,
        statusText: response.statusText,
        headers: response.headers,
      });
    })
    .catch(error => {
      throw new TypeError('Network request failed: ' + error.message);
    });
};

export default customAxiosFetch;
