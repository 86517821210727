import React, { useState } from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import { useNavigate, useLocation } from 'react-router-dom';
import './ArtistDashboard.css';

// GraphQL query to fetch the artist's merchandise page
const MY_MERCH_PAGE = gql`
  query MyMerchPage($ownerId: ID!) {
  myMerchPage(ownerId: $ownerId) {
    id
    title
    description
    ownerId
  }
}
`;

const CREATE_MERCHANDISE_PAGE = gql`
  mutation CreateMerchandisePage($input: MerchandisePageInput!) {
    CreateMerchandisePage(input: $input) {
      id
      title
      description
      ownerId
    }
  }
`;

function ArtistDashboard() {
  const navigate = useNavigate();
  const { state } = useLocation(); // Get state from navigation
  const title = state?.title; // Retrieve title from state
  const ownerId = state?.artistPageId;
  console.log("this should work: ", ownerId);
  console.log("this works: ", title);

  const [showForm, setShowForm] = useState(false);
  const [newMerchPage, setNewMerchPage] = useState({ title: '', description: '' });
  const [createMerchPage] = useMutation(CREATE_MERCHANDISE_PAGE);

  const token = sessionStorage.getItem('token');

  const { data, loading, error, refetch} = useQuery(MY_MERCH_PAGE, {
    variables: { ownerId },
    context: {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  });

  const handleCreateMerchPage = async () => {
    try {
      const { data } = await createMerchPage({
        variables: {
            input: {
                title: newMerchPage.title,
                description: newMerchPage.description,
                ownerId: ownerId, // Use artistPageId from state
              },
        },
        context: {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
        
      });
      refetch();

      setShowForm(false);
      setNewMerchPage({ title: '', description: '' });
    } catch (error) {
      console.error('Error creating merchandise page:', error);
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading merchandise pages: {error.message}</p>;

  const hasMerchandisePage = data?.myMerchPage.length > 0;

  return (
    <div className="user-dashboard">
      <h1>{title}</h1>
      <h2>Your Merchandise Page</h2>
      {!hasMerchandisePage && (
        <button className="create-button" onClick={() => setShowForm(true)}>
          + Create New Merchandise Page
        </button>
      )}
      {showForm && (
        <div className="create-form">
          <h3>Create New Merchandise Page</h3>
          <input
            type="text"
            placeholder="Title"
            value={newMerchPage.title}
            onChange={(e) => setNewMerchPage({ ...newMerchPage, title: e.target.value })}
          />
          <textarea
            placeholder="Description"
            value={newMerchPage.description}
            onChange={(e) => setNewMerchPage({ ...newMerchPage, description: e.target.value })}
          />
          <button onClick={handleCreateMerchPage}>Submit</button>
          <button onClick={() => setShowForm(false)}>Cancel</button>
        </div>
      )}
      <ul>
        {data?.myMerchPage.map((merchPage) => (
          <li key={merchPage.id}>
            <button
              className="merch-page-button"
              onClick={() => navigate(`/merchandisedashboard/${merchPage.id}`)}
            >
              {merchPage.title}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default ArtistDashboard;

  