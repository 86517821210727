import React from 'react';
import { useQuery, gql } from '@apollo/client';
import './Newsfeed.css';

// GraphQL query to fetch all news
const ALL_NEWS = gql`
  query AllNews {
    allNews {
      id
      createdAt
      NewsItem
    }
  }
`;

function Newsfeed() {
  const { data, loading, error } = useQuery(ALL_NEWS);

  if (loading) return <p>Loading news...</p>;
  if (error) return <p>Error loading news: {error.message}</p>;

  return (
    <div className="newsfeed">
      <h2>Latest News</h2>
      <ul>
        {data.allNews.map((news) => (
          <li key={news.id} className="news-item">
            <div className="news-date">
              {new Date(parseInt(news.createdAt)).toLocaleString()} {/* Convert timestamp to human-readable date */}
            </div>
            <div className="news-content">{news.NewsItem}</div>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Newsfeed;

