import { ApolloClient, InMemoryCache } from '@apollo/client';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import customAxiosFetch from './hooks/customfetch';
// Use the customAxiosFetch instead of the default fetch
const uploadLink = createUploadLink({
  uri: process.env.REACT_APP_GRAPHQL_ENDPOINT, // GraphQL endpoint
  fetch: customAxiosFetch,
  fetchOptions: {
    onProgress: (progress) => {
      console.log('Upload progress:', progress);
      // You can pass this progress back to your component to update a progress bar
    },
  },
});

const client = new ApolloClient({
  link: uploadLink,
  cache: new InMemoryCache(),
});

export default client;



/*
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
//import { createUploadLink } from 'apollo-upload-client';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';

const uploadLink = createUploadLink({
  uri: process.env.REACT_APP_GRAPHQL_ENDPOINT, // replace with your GraphQL endpoint
  
  fetch: (url, options) => {
    const { onUploadProgress } = options;
    
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open(options.method, url);
      
      Object.entries(options.headers || {}).forEach(([key, value]) => {
        xhr.setRequestHeader(key, value);
      });

      xhr.upload.onprogress = (event) => {
        if (onUploadProgress && event.lengthComputable) {
          const percentCompleted = Math.round((event.loaded * 100) / event.total);
          onUploadProgress(percentCompleted); // pass progress to your handler
        }
      };

      xhr.onload = () => resolve(new Response(xhr.response, { status: xhr.status }));
      xhr.onerror = () => reject(new Error('Network Error'));
      xhr.send(options.body);
    });
  },
});

const client = new ApolloClient({
  link: uploadLink,
  cache: new InMemoryCache(),
});

export default client

*/
