import React, { useEffect, useState } from "react";
import {
  ConnectAccountOnboarding,
  ConnectComponentsProvider,
} from "@stripe/react-connect-js";
import { loadConnectAndInitialize } from "@stripe/connect-js";

export default function StripeDash({ stripeSession }) {
  const [connectInstance, setConnectInstance] = useState(null);
  const stripePublicKey = "pk_live_51PQKUADMk9AEovmwLorxQT0i9q7H1veURQQbJVEyF2DxIiEGEE9iNjyyrjzokFcTIOIdZzDkGPwD2ITWuzZQLtMy00ZEgDAAaL"
  useEffect(() => {
    const fetchClientSecret = async () => {
      if (!stripeSession) {
        console.error("stripeSession is not available.");
        return;
      }

      // Load Connect and initialize with your publishable key and client secret
      const instance = loadConnectAndInitialize({
        publishableKey: stripePublicKey,
        fetchClientSecret: () => Promise.resolve(stripeSession), // Assuming stripeSession is the client secret
        appearance: {
          variables: {
            colorPrimary: "#228403",
          },
        },
      });

      setConnectInstance(instance);
    };

    fetchClientSecret();
  }, [stripeSession]);

  console.log("stripeSession test: ", stripeSession); // This should be a string

  return (
    <div className="container">
      <div className="banner">
        <h2>Stripe Dashboard</h2>
      </div>

      {/* Wrap the onboarding component with the provider only if connectInstance is ready */}
      {connectInstance ? (
        <ConnectComponentsProvider connectInstance={connectInstance}>
          <ConnectAccountOnboarding
            clientSecret={stripeSession} // Directly pass the string as the clientSecret
          />
        </ConnectComponentsProvider>
      ) : (
        <p>Loading Stripe session...</p>
      )}
    </div>
  );
}
