import React, { useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import './Register.css';

const REGISTER = gql`
  mutation Register($input: UserInput!) {
    Register(input: $input) {
      id
      mobile
      firstName
      lastName
      email
      artist
      venue
      fan
    }
  }
`;

const Register = () => {
  const [formData, setFormData] = useState({
    mobile: '',
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    bio: '',
    artist: false,
    venue: false,
    fan: false,
  });

  const [register, { loading, error }] = useMutation(REGISTER);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await register({
        variables: {
          input: formData,
        },
      });
      
      // Redirect to home after successful registration
      if (data && data.Register) {
        navigate('/udash');
      }
    } catch (err) {
      console.error("Registration failed", err);
    }
  };

  return (
    <div className="Register-container">
      
      <form className="Register-form" onSubmit={handleSubmit}>
      <div id='thename'>
            <svg width="100" height="100" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
              <circle cx="50" cy="50" r="50" fill="#a83434" /> {/* Red circle background */}
              <text 
                x="50%" 
                y="50%" 
                dominantBaseline="middle" 
                textAnchor="middle" 
                fontFamily="Arial" 
                fontSize="50" 
                fill="white"
              >
                GiG
              </text> {/* White G */}
            </svg>
            <h2>about Australia</h2>
          </div>
        <h2>Register</h2>
        <input
          type="text"
          name="firstName"
          value={formData.firstName}
          onChange={handleChange}
          placeholder="First Name"
          required
        />
        <input
          type="text"
          name="lastName"
          value={formData.lastName}
          onChange={handleChange}
          placeholder="Last Name"
          required
        />
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          placeholder="Email"
          required
        />
        <input
          type="password"
          name="password"
          value={formData.password}
          onChange={handleChange}
          placeholder="Password"
          required
        />
        <input
          type="text"
          name="mobile"
          value={formData.mobile}
          onChange={handleChange}
          placeholder="Mobile"
          required
        />
        <textarea
          name="bio"
          value={formData.bio}
          onChange={handleChange}
          placeholder="Bio"
        />
        
        <div>
          <label style={{marginRight: 20,marginLeft: 20,}}>
            <input
              type="checkbox"
              name="artist"
              checked={formData.artist}
              onChange={handleChange}
            />
            Artist
          </label>
          <label style={{marginRight: 20,}}>
            <input
              type="checkbox"
              name="venue"
              checked={formData.venue}
              onChange={handleChange}
            />
            Venue
          </label>
          <label style={{marginRight: 10,}}>
            <input
              type="checkbox"
              name="fan"
              checked={formData.fan}
              onChange={handleChange}
            />
            Fan
          </label>
        </div>
        <button type="submit" disabled={loading}>
          {loading ? 'Registering...' : 'Register'}
        </button>
        {error && <p className="error-message">Error: {error.message}</p>}
      </form>
    </div>
  );
};

export default Register;
