// src/pages/MerchandiseDetailPage.js

import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import StripeDash from '../components/StripeDash';
import MerchandiseItem from '../components/MerchandiseItem';  // Import the MerchandiseItem component
//import backgroundImage from '../assets/images/background.jpg'; // Import the image
const GET_MERCH_PAGE = gql`
  query GetMerchPage($input: MerchInput!) {
    getMerchPage(input: $input) {
      id
      title
      description
      items {
        id
        title
        description
        price
        attributes
        mainImage
        additionalImages
        merchandisePageId
      }
    }
  }
`;

const MerchandiseDetailPage = () => {
  const { id } = useParams(); // Get the id from the URL parameters
  const { loading, error, data } = useQuery(GET_MERCH_PAGE, {
    variables: { input: { id } },  // Pass 'input' object as required by the schema
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  const { title, description, items } = data.getMerchPage;
  const backgroundimage = `https://gigabout.com.au/merchimages/${id}/background.jpg`

  return (
    <div
    style={{
      position: 'relative',
      minHeight: '100vh',
      backgroundImage: `url(${backgroundimage})`,
      backgroundRepeat: 'repeat',
      backgroundSize: 'cover',
    }}
  >
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(255, 255, 255, 0.886)', // Adjust the color and transparency
        zIndex: 1,
      }}
    />
    <div style={{ position: 'relative', zIndex: 2 }}>
      <h1>{title}</h1>
      <p>{description}</p>
      <p>This is a sample page. You can't buy anything yet</p>
      <h2>Products</h2>
      <div 
        style = {{paddingLeft: 20,
                  paddingRight: 20,
        }}>
      <div 
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
          gap: '16px',
        }}
      >
        {items.map((item) => (
          <MerchandiseItem key={item.id} item={item} onAddToCart={(item) => {/* handle add to cart */}} />
        ))}
      </div>
    </div>
    
    </div>
    
    </div>
    
  );
};

export default MerchandiseDetailPage;
