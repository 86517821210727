import React from 'react';
import './Home.css';
import About from './About';
import LoginButton from '../components/LoginButton';
import SignUp from './SignUp';
import MerchandiseListPage from './MerchandiseListPage';
import RegisterButton from '../components/RegisterButton';
import Newsfeed from '../components/NewsFeed';


function Home() {
  return (
    <div className="Gigabout">
      <title>Gigabout</title>
      <header className="App-header">
        <div className="header-container">
          <div id='thename'>
            <svg width="100" height="100" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
              <circle cx="50" cy="50" r="50" fill="#a83434" /> {/* Red circle background */}
              <text 
                x="50%" 
                y="50%" 
                dominantBaseline="middle" 
                textAnchor="middle" 
                fontFamily="Arial" 
                fontSize="50" 
                fill="white"
              >
                GiG
              </text> {/* White G */}
            </svg>
            <h2>about Australia</h2>
          </div>
          <a href='/merchandise'>View merchandise Pages</a>
          
        </div>
        
        <RegisterButton/>
        <br/>
        <Newsfeed/>
        
      </header>
      

    </div>
  );
}

export default Home;

